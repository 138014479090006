const getURLQueries = _query => {
	const allURLData = {
		queries: [],
		hash: "",
	};

	const valuePairs = [];
	const urlHash = window.location.hash;
	const hashExists = !!urlHash;

	const checkForQuery =
		window.location.search.indexOf("?") + 1 < window.location.search.length
			? window.location.href.indexOf("?") + 1
			: false;
	let query;
	if (checkForQuery) {
		const queries = window.location.href.replace(urlHash, "").slice(checkForQuery).split("&");
		const tempURLVars = [];

		if (queries?.length > 0) {
			for (let i = 0; i < queries.length; i++) {
				query = queries[i].split("=");
				/* eslint-disable prefer-destructuring */
				valuePairs[query[0]] = query[1];
				tempURLVars[query[0]] = query[1];
			}

			allURLData.queries = tempURLVars;
		} else {
			allURLData.queries = null;
		}
	}

	if (hashExists) {
		allURLData.hash = urlHash;
	}

	if (_query && _query.length > 0) {
		return allURLData.queries[_query];
	}
	return allURLData;
};

export { getURLQueries };
